.inputLabel {
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 10px;
    transition: 0.2s ease all;
    font-family: 'degular-display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #A8A8A8;
  }

  .inputField:focus {
    outline: none;
    background-color: rgba(250, 250, 249, 1);
    border: 1px solid rgba(255, 77, 0, 1);
    padding-top: 20px;
    caret-color: #FF4D00;
    box-shadow: 0 0 8px rgba(255, 77, 0, 0.6);
  }

  .inputLabel.filled {
    color: #9ca3af;
  }
  

  .inputField:focus + .inputLabel, .inputLabel.focused {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.04em;
    top: 2px;
    left: 15px;
    color: #FF4D00;
  }
  
  .inputField:focus, .inputField:not(:placeholder-shown) {
    padding-top: 15px;
  }

  .inputField {
    position: relative;
    width: 100%;
    height: 40px;
    padding: '0 18px';
    border: 1px solid #E8E0DC;
    border-radius: 0.375rem; 
    color: #1F2937;
  }

  .inputField.textarea {
    height: 200px;
    border-radius: 4px;
    letter-spacing: 0.03em;
    padding-top: 21px;

  }
  
  .spinner {
    display: inline-block;
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .customButton {
    transition: box-shadow 0.2s ease, background-color 0.2s ease, background 0.2s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .customButton:active {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    transform: translateY(2px) scale(0.98); 
  }

  .hover-effect:hover {
    color: #FF4D00;
  }



@media (max-width: 767px) {
  .inputLabel {
    font-size: 22px;
    line-height: 29.19px; 
    top: 15px; 
  }
  .inputField:focus + .inputLabel, .inputLabel.focused {
    font-size: 14px;
    line-height: 18.58px;
    top: 5px;
  }
  .inputField:focus, .inputField:not(:placeholder-shown) {
    padding-top: 20px;
  }
  .inputField.textarea {
    padding-top: 30px;
  }
  .inputField:not(.textarea) {
    height: 60px; 
  }
}